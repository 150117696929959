import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import ExcercisePage from 'components/CanvasToolPlus/ExcercisePage';

// #region constants
const propTypes = {
  worksheetId: PropTypes.string.isRequired,
  worksheetData: PropTypes.object,
};

const defaultProps = {};

const AnswerPopup = ({
  worksheetId,
  worksheetData
}) => {
  const [visiblePopup, setVisiblePopup] = useState(false);

  const togglePopup = () => {
    setVisiblePopup(v => !v);
  }

  return <React.Fragment>
    <Button onClick={togglePopup} type="primary">
      Keys
    </Button>

    <Modal
      title={'WORKSHEET'}
      open={visiblePopup}
      onCancel={togglePopup}
      footer={
        <Button onClick={togglePopup}>
          Close
        </Button>}
      width={'100%'}
      centered
      bodyStyle={{ height: 'calc( 100vh - 140px )', minHeight: 300, overflowY: 'auto' }}
    >
      <ExcercisePage
        key='preview-answer-popup'
        idCurrent={worksheetId}
        file={worksheetData}
        ref={{}}
        // isVisibleResult={true}
        styles={{ height: '100vh - 50px' }}
        isPreview={true}
      />
    </Modal>
  </React.Fragment>;
}

AnswerPopup.propTypes = propTypes;
AnswerPopup.defaultProps = defaultProps;

export default AnswerPopup;