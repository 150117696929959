import _ from "lodash";

//Tạo data đúng từ đề để hiển thị xem trước
export function createPreviewData(data) {
    const newData = data.flat().map((item) => {
        const isCorrect = item.text === "yes";
        return ({
            ...item,
            isCorrect: isCorrect,
            isSelected: isCorrect,
            time: Date.now()
        });
    });
        const groups = _.groupBy(newData, (x) => x.groupName);
    const groupChoice = Object.values(groups);

    return groupChoice;
}