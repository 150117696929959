import _ from "lodash";

//Tạo data đúng từ đề để hiển thị xem trước
export function createPreviewData(data) {
    const newData = data.map((item) => {
        return ({ ...item, userAnswers: item.text, isCorrect: true });
    });
    const groups = _.groupBy(newData, (x) => x.groupName);
    const groupChoice = Object.values(groups);

    return groupChoice;
}