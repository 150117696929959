
//Tạo data đúng từ đề để hiển thị xem trước
export function createPreviewData(data) {
    const userAnswer = [];

    data.flat().forEach((item, index) => {
        if (item.text === 'yes') {
        userAnswer.push({ ...item, index });
        }
    });

    return userAnswer;
}