import _ from "lodash";

//Tạo data đúng từ đề để hiển thị xem trước
export function createPreviewData(data) {
    const answers = [];
    const booleanArray = [];
    const listBoolean = {};
    const groups = _.groupBy(data, (o) => o.text);
    for (const groupKey in groups) {
        const keys = groups[groupKey].map((e) => e.countMatch);
        const answer = keys.join('-');
        answers.push(answer);
        booleanArray.push(true);
        listBoolean[answer] = true;
    }
    return {
        userSelect: [],
        userAnswers: answers,
        arrayAnswers: data?.map((x) => x.text),
        listBoolean,
        booleanArray
    };
}