import { useParams, useLocation, useHistory } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Row, Space } from 'antd';
import { CardFooter } from 'reactstrap';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import ExcercisePage from 'components/CanvasToolPlus/ExcercisePage';
import Result from 'components/CanvasToolPlus/ExcercisePage/Result';
import NotData from 'components/Error/NotData';
import Loading from 'components/Loading';

import ExerciseBreadcrumb from './ExerciseBreadcrumb';
import withAuthenticator from 'HOCs/withAuthenticator';
import { fetcher } from './api';
import * as functions from 'components/functions';
import { getItemLocalStorage } from 'utils/localStorage';
import { KEY_STORAGE_ASSIGNMENT_PARTS } from 'utils/localStorage';
import { useDispatch } from 'react-redux';
import * as ActionTypes from 'modules/SessionModule/actions/types';
import AnswerPopup from './AnswerPopup';

const LiveWorksheetType = ({ loggedInUser }) => {
  const { assignmentsMix4Id, assignmentId, attachmentId, sessionId, classId } = useParams();
  const assignmentID = assignmentId ?? assignmentsMix4Id;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const refSubmit = useRef({});
  const { takeExamTime, asrId } = queryString.parse(location.search);

  const [resultData, setResultData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isTry, setIsTry] = useState(false);
  const [file, setFile] = useState(null);
  const [user, setUser] = useState(null);

  const handleSubmit = async () => {
    const rs = await refSubmit.current.submit();
    if (rs && rs.info === 'ok') {
      const result = refSubmit.current.result();
      setResultData(result);
      setIsTry(true);
    }
  };

  const handleTryAgain = () => {
    refSubmit.current.tryAgain();
    setResultData(null);
    setIsTry(false);
  };

  const handleNext = () => {
    //Check chuyển part tiếp theo (nếu có)
    const outline = getItemLocalStorage(KEY_STORAGE_ASSIGNMENT_PARTS);
    if (outline) {
      const currentIndex = outline.findIndex((part) => part.id === attachmentId);
      if (currentIndex >= 0 && currentIndex < outline.length - 1 && outline[currentIndex + 1]?.id) {
        //Chuyển sang part tiếp theo
        const nextPart = outline[currentIndex + 1];
        dispatch({
          type: ActionTypes.SAVE_SELECTED_PART,
          selectedPart: { ...nextPart, index: currentIndex + 1 },
          groupPart: outline,
        });
        const nextHref = window.location.href.replace(attachmentId, nextPart.id);
        window.location.href = nextHref;
        return true;
      }

      //Check chuyển assignment tiếp theo (nếu có) => Chưa xử lý vì có type có chia part, có type không
    }

    //Mặc định chuyển về trang assignment
    const pathname = `/ames/class/${classId}/session/${sessionId}/${assignmentId ? `assignments` : `assignmentsMix4`}`;
    history.push(pathname);

    //Follow cũ: làm bài xong thì chuyển về trang kết quả (trang kết quả chưa hoàn thiện nên không dùng nữa)
    // const type = 'LIVEWORKSHEET';
    // const pathname = `/ames/class/${classId}/session/${sessionId}/${
    //   assignmentId ? `assignment` : `assignmentsMix4Id`
    // }/${assignmentID}/attachment/${attachmentId}/results`;
    // const linkQuestion = {
    //   pathname,
    // search: `?type=${type}&asrId=${asrId}&takeExamTime=${takeExamTime}`,
    // };
    // history.push(linkQuestion);
  };

  useEffect(() => {
    if (loggedInUser) {
      const { typeLogin, userMyames } = loggedInUser;

      fetcher({
        attachmentId,
        sessionId,
        assignmentId: assignmentID,
        takeExamTime,
        userId: userMyames?.StudentId,
        asrId,
      })
        .then((res) => {
          if (res[0] === undefined) return;
          const { id, fileName, jsonData } = res[0];
          setFile({ id, fileName, jsonData });
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });

      if (typeLogin === 'ames') {
        setUser(userMyames);
      }
    }
    return () => {
      setFile();
    };
  }, [asrId, assignmentID, attachmentId, location.search, loggedInUser, sessionId, takeExamTime]);

  if (isLoading) return <Loading />;
  if (!file) return <NotData />;

  return (
    <div>
      <ExerciseBreadcrumb assignmentId={assignmentId} assignmentsMix4Id={assignmentsMix4Id} />
      {!!resultData && <Result data={resultData} />}
      <div style={{ height: `calc(100vh - 130px - 90px - ${!!resultData ? 64 : 0}px)`, overflowY: 'auto' }}>
        {file ? (
          <ExcercisePage
            file={file}
            ref={refSubmit}
            isVisibleResult={false}
            styles={{ height: '100vh - 50px' }}
            studentData={{
              studentId: user?.StudentId ?? functions.getUser().StudentId,
              sessionId,
              assignmentId: assignmentID,
              attachmentId,
              classId: classId ?? 0
            }}
          />
        ) : (
          <NotData />
        )}
      </div>
      <CardFooter style={{ textAlign: 'end', padding: 10 }}>
        <Row>
          <Col span={8}>
            <div />
          </Col>
          <Col span={8} className="d-flex justify-content-center align-items-center">
            <div id="Footer-ExcercisePage-Audio" />
          </Col>
          <Col span={8}>
            <Row justify="end">
              <Space>
                {isTry ? (
                  <>
                    <AnswerPopup worksheetData={file} worksheetId={attachmentId}/>
                    <Button onClick={handleTryAgain} type="primary">
                      Try again
                    </Button>
                    <Button onClick={handleNext} type="primary">
                      Next
                    </Button>
                  </>
                ) : (
                  <Button onClick={handleSubmit} type="primary">
                    Submit
                  </Button>
                )}
              </Space>
            </Row>
          </Col>
        </Row>
      </CardFooter>
    </div>
  );
};

LiveWorksheetType.propTypes = {
  loggedInUser: PropTypes.object,
};

export default withAuthenticator(LiveWorksheetType);
