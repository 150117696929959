import { useParams, useLocation, useHistory } from 'react-router-dom';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Col, Row, Space, Spin } from 'antd';
import { CardFooter } from 'reactstrap';
import NotData from 'components/Error/NotData';
import Loading from 'components/Loading';
import queryString from 'query-string';
import SlideQuestionsBreadcrumb from './components/SlideQuestionsBreadcrumb';
import * as functions from 'components/functions';
import { useDispatch } from 'react-redux';
import VideoSlide from './components/QuestionTypes/VideoSlide';
import WorksheetSlide from './components/QuestionTypes/WorksheetSlide';
import { useQuery } from 'react-query';
import { query } from 'helpers/QueryHelper';
import WorksheetAnswerPopup from '../LiveWorksheetType/AnswerPopup';

const SlideQuestions = (props) => {
  const { history, location, match } = props;
  const dispatch = useDispatch();
  const slideRef = useRef({});

  const { assignmentsMix4Id, assignmentId, attachmentId, sessionId, classId } = useParams();
  const { takeExamTime, asrId } = queryString.parse(location.search);
  const assignmentID = assignmentId ?? assignmentsMix4Id;

  const [isSubmited, setIsSubmited] = useState(false);

  const { data: outline, isLoading } = useQuery('KEY_GET_SLIDES', () =>
    query('p_AMES247_SessionAssignment_Questions_VideoWorksheet', {
      SessionId: sessionId,
      assignmentId: assignmentID,
    })
  );

  const studentId = functions.getUser().StudentId ?? 0;
  const totalSlide = outline?.length ?? 0;

  //Khi mới chuyển từ assigments qua thì attachmentId = ":attachmentId"
  const index = outline?.findIndex((slide) => slide.id === attachmentId);
  const currentSlideIndex = index > 0 ? index : 0;
  const currentSlide = outline?.[currentSlideIndex];
  const slideType = currentSlide?.type;
  const assignmentRoute = `/ames/class/${classId}/session/${sessionId}/${assignmentId ? 'assignments' : 'assignmentsMix4'
    }/`;
  const currentRoute = location.pathname + location.search;

  const handleSubmit = async () => {
    const rs = await slideRef.current.submit();
    switch (slideType) {
      case 'VIDEO':
        handleNext();
        break;

      case 'LIVEWORKSHEET':
        setIsSubmited(true);
        break;

      default:
        break;
    }
  };

  const handleTryAgain = () => {
    slideRef.current.tryAgain();
    setIsSubmited(false);
  };

  const handleNext = () => {
    setIsSubmited(false);
    //slide cuối thì chuyển hướng về assignments
    if (currentSlideIndex >= outline.length - 1) {
      history.push(assignmentRoute);
      return;
    }
    //chuyển tới slide tiếp theo
    const nextId = outline[currentSlideIndex + 1].id;
    history.push(currentRoute.replace(attachmentId, nextId));
  };

  const handleBack = () => {
    setIsSubmited(false);
    //slide đầu thì chuyển hướng về assignments
    if (currentSlideIndex <= 0) {
      history.push(assignmentRoute);
      return;
    }
    //chuyển tới slide trước
    const backId = outline[currentSlideIndex - 1].id;
    history.push(currentRoute.replace(attachmentId, backId));
  };

  const RenderQuestion = useCallback(() => {
    const childProps = {
      ref: slideRef,
      data: currentSlide,
      studentData: {
        studentId,
        sessionId,
        assignmentId: assignmentID,
        attachmentId: currentSlide?.id,
        classId: classId ?? 0,
      },
    };
    switch (slideType) {
      case 'VIDEO':
        return <VideoSlide {...childProps} />;
        break;

      case 'LIVEWORKSHEET':
        return <WorksheetSlide {...childProps} />;
        break;

      default:
        return <NotData />;
        break;
    }
  }, [currentSlide?.id]);

  if (isLoading) {
    return (<Loading />)
  }

  return (
    <div>
      <SlideQuestionsBreadcrumb />
      {!!slideType ? (
        <div>
          <RenderQuestion />
          <CardFooter style={{ padding: 10 }}>
            <Row>
              <Col span={8}>
                <div className="d-flex justify-content-start align-items-center">
                  <Button onClick={handleBack} type="primary">
                    Back
                  </Button>
                </div>
              </Col>
              <Col span={8}>
                <div className="d-flex justify-content-center align-items-center">
                  <div>
                    {currentSlideIndex + 1} / {totalSlide}
                  </div>
                  <div id="Footer-ExcercisePage-Audio" style={{ marginTop: 10 }} />
                </div>
              </Col>
              <Col span={8}>
                <Row justify="end">
                  <Space>
                    {isSubmited ? (
                      <>
                        {slideType === 'LIVEWORKSHEET'
                          && (<WorksheetAnswerPopup worksheetId={currentSlide?.id}/>)}
                        <Button onClick={handleTryAgain} type="primary">
                          Try again
                        </Button>
                        <Button onClick={handleNext} type="primary">
                          Next
                        </Button>
                      </>
                    ) : (
                      <Button onClick={handleSubmit} type="primary">
                        Submit
                      </Button>
                    )}
                  </Space>
                </Row>
              </Col>
            </Row>
          </CardFooter>
        </div>
      ) : (
        <NotData />
      )}
    </div>
  );
};

SlideQuestions.propTypes = {};

export default SlideQuestions;
