import _ from "lodash";

//Tạo data đúng từ đề để hiển thị xem trước
export function createPreviewData(data) {

    const newData = data.map((item) => {
        const [type, answer] = item.text.split(':');
        if (type === 'Drag') {
            return ({ ...item, answer, image: undefined, isCorrect: undefined });
        } else {
            const dragItem = data.find(i => (i.text === `Drag:${answer}`))
            return ({ ...item, answer, image: dragItem?.image, isCorrect: true });
        }
    });

    return newData;
}